


















































import { Component, Prop } from "vue-property-decorator";
import VueBase from '@/VueBase'
import moment from 'moment'

@Component({ name: 'shareDialog' })
export default class ShareDialog extends VueBase {
	@Prop() item: any
  @Prop() settingInte: any
  // @Prop() getTableData: any
  @Prop() source_type: any
  @Prop() id: any
  @Prop() show: boolean|undefined
  @Prop() list: any

	get visible() {
		if(this.show && this.id) {
			this.getDetail()
			return this.show
		}
		this.show && this.getShareUrl(5, false)
		return this.show
	}
	set visible(val) {
		this.$emit('update:show', val)
	}

	private showLoading: boolean = false
  private shareFormData: any = {
    defaultTime: 5,
    time: '',
    url: ''
  }

	private pickerOptions:any = {
		disabledDate(time: any) {
			// console.log('time', time)
			return time.getTime() < Date.now() - 8.64e7;
		},
	}
  private token = ''
  private term: any = ''
  private isCustom: boolean = false
  private async getShareUrl(term: any, isCustom: boolean) {
    this.showLoading = true
    this.term = term
    this.isCustom = isCustom
    let { data, msg, status} = await this.services.share.generate()
    this.showLoading = false
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.shareFormData.url = data.full_url
    this.token = data.token
  }

  private disabled = false
  private async commit() {
    if(this.id) {
			this.shareEdit()
			return
		}
    if (!this.term) {
      this.$message.warning('请选择到期时间')
      return
    }
    let params: any = {
      expire_at: this.isCustom ? this.term : new Date(new Date().getTime() + (this.term * 24 * 60 * 60 * 1000))
    }
    params.expire_at = moment(params.expire_at).format('YYYY-MM-DD HH:mm:ss').slice(0, 11)+'23:59:59'
    let api = ''
    if (this.source_type === 1) {
      api = 'shareUrlVuln'
      params.vul_id = this.item.id
      params.title = this.itemTitle()
    } else {
      api = 'shareUrlSca'
      params.sca_id = this.item.id
      params.title = this.item.title
      params.project_id = this.item.projectId
      params.project_version_id = this.item.versionId
    }
    this.showLoading = true
    params.token = this.token
    let { msg, status} = await this.services.share[api](params)
    this.showLoading = false
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.$copyText(this.shareFormData.url).then(() => {
        this.onCopy()
      }, () => {
        this.onError()
      })
  }

  private handleChange(val: any) {
		if (val == 0) {
			this.term = null
      this.isCustom = false
			return
		}
    this.term = val
    this.isCustom = false
  }
  private async handleTimeChange(time: any) {
		if(!time) return
		if(this.id) return
    this.term = time
    this.isCustom = true
  }
	async shareEdit() {
    if (!this.shareFormData.time) {
      this.$message.warning('请选择到期时间')
      return
    }
		this.showLoading = true
		let res = await this.services.share.shareEdit(this.id, {
			title: this.shareFormData.title,
			expire_at: moment(this.shareFormData.time).format('YYYY-MM-DD HH:mm:ss').replace('12:00:00', '23:59:59'),
			status: this.shareFormData.status
		})
		this.showLoading = false
		this.$emit("list")
		if(res.status == 201) {
			this.$message.success('有效期修改成功')
      this.handleClose()
			return
		}
		this.$message.error('有效期修改失败，请重试')

	}
  private showTip: boolean = false
  private copySuccess: boolean = false

  private onError() {
    this.copySuccess = false
    this.showTip = true
    setTimeout(() => {
      this.showTip = false
    }, 3000);
  }

  private onCopy() {
    this.copySuccess = true
    this.showTip = true
    this.disabled = true
  }

  private handleClose() {
    this.shareFormData = {
      defaultTime: 5,
      time: '',
      url: location.href
    }
    this.visible = false
    this.disabled = false
    this.copySuccess = false
    this.showTip = false
  }

  private itemTitle() {
    let title = `${this.item.uri}  ${this.item.http_method} 出现 ${this.item.type}`
    if (this.item.taint_position) {
      title += '位置:' + this.item.taint_position
    }
    return this.item.shareTitle || title
  }

	async getDetail() {
		let { data, msg, status} = await this.services.share.shareDetail(this.id)
		if (status !== 201) {
			this.$message.error(msg)
			return
		}
		this.shareFormData = {
			defaultTime: 0,
			time: moment(data.expire_at).format('YYYY-MM-DD HH:mm:ss'),
			url: data.full_url,
			...data
		}
	}
}

